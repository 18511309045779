import React, { useEffect, useState } from "react"
import _ from "underscore"
import { Button } from "antd"
import ActivityLogsView from "./ActivityLogsView"
import { errorAlert } from "../../utilities"
import commonStrings from "../../commonStrings"
import InputCellTypeNumber from "../CommonComponents/Forms/InputCellTypeNumber"

function ProgramMatrix({
  matrix1: ProgramMatrix,
  currentSchool,
  programAssociatedSchools,
  dataYml,
  socialData,
  behaviorSupports,
  extracurricularActivity,
  adultsel,
  currentUserRole,
}) {
  // const parsedDataYml = JSON.parse(dataYml);
  const parsedDataYml = dataYml ? JSON.parse(dataYml) : null
  const parsedSocialData = socialData ? JSON.parse(socialData) : null
  const parsedbehaviorSupports = behaviorSupports
    ? JSON.parse(behaviorSupports)
    : null
  const parsedextracurricularActivity = extracurricularActivity
    ? JSON.parse(extracurricularActivity)
    : null
  const parsedadultsel = adultsel ? JSON.parse(adultsel) : null

  // const parsedSocialData = JSON.parse(socialData);
  // const parsedbehaviorSupports = JSON.parse(behaviorSupports);

  const [matrix1, setMatrix1] = useState(ProgramMatrix)
  const [matrix, setMatrix] = useState([])

  const handleErrors = (errors) => {
    errorAlert({ html: errors.join("<br/><br/>") })
  }

  const backToDashboard = () => {
    if (currentUserRole.includes("school")) {
      window.location.href = `/?school_id=${currentSchool.id}`
    } else if (currentUserRole.includes("program")) {
      window.location.href = "/"
    }
  }

  const getProgramMatrix = () => {
    APIClient.get("/program_matrix/current_program_matrix_get", (response) => {
      if (response) {
        setMatrix1(response)
      }
    })
  }

  const getStudentPopulation = (e) => {
    showSaving()
    APIClient.put(
      "/program_matrix/update_student_population",
      {
        student_population_count:
          parseInt(e.target.value, 10) === 0 ? 1 : parseInt(e.target.value, 10),
      },
      () => {
        hideSaving()
        getProgramMatrix()
      },
      () => {
        hideSaving()
      },
    )
  }

  const viewReport = () => {
    if (!_.isEmpty(matrix1.completed_items)) {
      APIClient.put(
        "/program_matrix/submit",
        {},
        () => {
          window.location.href = "/program_matrices/reports?name=SEL_Scan"
        },
        (errors) => {
          handleErrors(errors)
        },
      )
    } else {
      errorAlert({ text: "Please add at least one completed activity" })
    }
  }

  const viewAllMatrices = () => {
    APIClient.get(
      "/program_matrix/program_matrix_items/activity_status",
      (response) => {
        if (!_.isNull(response)) {
          setMatrix(response.program_matrix_items)
        } else {
          setMatrix([])
        }
      },
    )
  }
  function findObjectById(object, array) {
    const resultObject = array.find((item) => item.id === object.id)
    if (resultObject === undefined) {
      window.location.href = "/"
    }
  }

  useEffect(() => {
    viewAllMatrices()
  }, [])

  useEffect(() => {
    findObjectById(currentSchool, programAssociatedSchools)
  }, [currentSchool, programAssociatedSchools])

  return (
    <div className="rubric program-matrix mb-20">
      <div className="header d-flex justify-content-between">
        <Button
          className="btn-activity"
          block
          onClick={() => backToDashboard()}
        >
          {commonStrings.backToDashboard}
        </Button>

        <div className="studentPopulation">
          <div>
            <InputCellTypeNumber
              min={1}
              handleBlur={getStudentPopulation}
              defaultValue={matrix1.student_population}
              name="student-population"
              controls={false}
            />
          </div>
          <div>
            <span className="boldText">
              {" Please enter student population"}
            </span>
          </div>
        </div>

        <Button className="btn-activity" block onClick={() => viewReport()}>
          {commonStrings.viewReports}
        </Button>
      </div>

      {matrix.map((value, index) => (
        <ActivityLogsView
          key={index}
          title={value.category}
          activities={value.activities}
          totalActivities={value.total_activities}
          completedActivities={value.completed_activities}
          matrix1={matrix1}
          workflowState={matrix1.workflow_state}
          dataYml={parsedDataYml}
          socialData={parsedSocialData}
          behaviorSupports={parsedbehaviorSupports}
          extracurricularActivity={parsedextracurricularActivity}
          adultsel={parsedadultsel}
        />
      ))}
    </div>
  )
}

export default ProgramMatrix
