import React from "react"
import _ from "underscore"
import commonStrings from "../../commonStrings"
import componentStyles from "../../styles"

function ProgramMatrixHeader({
  completedActivities,
  studentPopulation,
  fieldsValues,
}) {
  const tooltipText = "<h6 class='mb-0'>Directions: Consider each domain (e.g., Instructional Practices) and identify the activities within the domain that have the potential to promote or reinforce SEL. Once you have inventoried activities in all domains, click \"Generate Report\" and review your reports. You may update activities at any time by clicking \"Update Matrix\".</h6>"

  return (
    <>
      <div className="header d-flex justify-content-between align-items-center">
        <div>
          <h1 className="mr-2">
            <a href="/program_matrices" style={componentStyles.noStyling}>
              {commonStrings.programMatrix}
            </a>
          </h1>
          <i
            data-toggle="popover"
            data-content={tooltipText}
            className="fa fa-info-circle align-top text-ua-dark-blue mr-3"
          />
        </div>
        {!(fieldsValues.category === "Adult SEL") && (
          <div className="studentPopulationHeader">
            <span>
              {`Student Population: ${
                _.isNull(studentPopulation)
                  ? "No data found"
                  : studentPopulation
              }`}
            </span>
          </div>
        )}

        <div>
          <span style={componentStyles.activitiesCount}>
            {commonStrings.completedActivities}: {completedActivities}
          </span>
        </div>
      </div>
      <div style={componentStyles.horizontalRuler} />
    </>
  )
}

export default ProgramMatrixHeader
