import React, { useState, useEffect } from "react"
import {
  Row, Col, Space, Tooltip, Dropdown, Modal, Button,
} from "antd"
import { UpCircleOutlined, DownOutlined, UpOutlined } from "@ant-design/icons"
import "../../../assets/stylesheets/rubrics/implementation/implementationRubric.scss"
import _, { last } from "underscore"
import ScrollToTop from "react-scroll-to-top"
import {
  areYouSure,
  errorAlert,
  findPreviousMonthRecord,
  formatTimestamp,
  isTenureCompleted,
} from "../../utilities"
import commonStrings from "../../commonStrings"
import HeaderButtons from "./Components/HeaderButtons"
import Collapsible from "./Components/Collapsible"
import ImplementationRubricStage from "./Stage"
import "animate.css"
import ImplementationReport from "./Report"
import ImplementationPlan from "./Plan"
import componentStyles from "../../styles"
import RubricsListing from "../rubrics/RubricsListing"
import SuccessAlert from "../rubrics/SuccessAlert"
import Loader from "../CommonComponents/Loader"
import CommonColors from "../../commonColors"

const ImplementationRubric = ({
  rubric: rubricInitial,
  canCreate,
  rubricsList,
  currentSchool,
  rubricsDifference,
  type,
  rubricType,
  currentSchoolLists,
  schoolAssociatedWithRubrics,
}) => {
  const [flagRender, setFlagRender] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [showPlan, setShowPlan] = useState(false)
  const [updatedRubric, setUpdatedRubric] = useState([])
  const [rubric, setRubric] = useState(rubricInitial)
  const [dropDownIconRotate, setDropDownIconRotate] = useState(false)
  const [showListingSubmitted, setListingSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [monthlyScoreDiff, setMonthlyScoreDiff] = useState(null)
  const [latestUpdatedAt, setLatestUpdatedAt] = useState({})

  const reportDate = new Date(rubric.updated_at).toLocaleDateString("en-US")
  const [labelClicked, setLabelClicked] = useState(false)
  function updateIndicatorWithRubric(obj, rubric) {
    const updatedIndicator = obj.indicators.map((indicator) => {
      const matchingRubric = rubric.find((r) => r.id === indicator.id)
      if (matchingRubric) {
        return {
          ...indicator,
          rating: matchingRubric.rating,
          explanation: matchingRubric.explanation,
          body: matchingRubric.body,
        }
      }
      return indicator
    })
    return {
      ...obj,
      indicators: updatedIndicator,
    }
  }

  function processObjects(objectsArray, listSchool) {
    const currentUrl = window.location.href

    if (currentUrl.includes("/implementation_rubrics/")) {
      const urlParts = currentUrl.split("/implementation_rubrics/")
      const idFromUrl = parseInt(urlParts[1], 10)
      const matchingObject = objectsArray.find((obj) => obj.id === idFromUrl)
      const SchoolbyId = matchingObject.school_id
      const matchingSchool = listSchool.find((obj) => obj.id === SchoolbyId)

      if (!matchingSchool) {
        window.location.href = "/"
      } else {
        setIsLoading(false)
      }
    }
  }
  useEffect(() => {
    processObjects(schoolAssociatedWithRubrics, currentSchoolLists)
  }, [schoolAssociatedWithRubrics, currentSchoolLists])
  const findObjectBeforeId = (array, targetId) => {
    const arrayOfObjects = array.filter((item) => item.submitted_at !== null)

    for (let i = 0; i < arrayOfObjects.length; i += 1) {
      if (arrayOfObjects[i].id === targetId) {
        if (i === 0) {
          return { updated_at: null }
        }
        if (_.isNull(arrayOfObjects[i - 1].submitted_at)) {
          return { updated_at: null }
        }
        return arrayOfObjects[i - 1]
      }
    }
    return { updated_at: null }
  }

  useEffect(() => {
    if (!_.isEmpty(updatedRubric)) {
      setRubric(updateIndicatorWithRubric(rubric, updatedRubric))
    }
  }, [updatedRubric])

  useEffect(() => {
    setListingSubmitted(false)
  }, [window.location.href])

  const unsubmitClicked = (type, rubric) => {
    areYouSure("info", () => {
      setSubmitting(true)
      APIClient.put(`/${type}_rubrics/${rubric.id}/unsubmit`, {}, () => {
        window.location.href = `/${type}_rubrics/${rubric.id}`
      })
    })
  }
  const handleErrors = (errors) => {
    setSubmitting(false)
    errorAlert({ html: errors.join("<br/><br/>") })
  }

  const confirmSubmit = (type, rubric) => {
    areYouSure("info", () => {
      setSubmitting(true)
      APIClient.put(
        `/${type}_rubrics/${rubric.id}/submit`,
        {},
        () => {
          window.location.href = `/${type}_rubrics/${rubric.id}`
        },
        (errors) => {
          handleErrors(errors)
        },
      )
    })
  }

  const submitClicked = (type, rubric) => {
    APIClient.put(
      `/${type}_rubrics/${rubric.id}/validate`,
      {},
      () => {
        confirmSubmit(type, rubric)
      },
      (errors) => {
        handleErrors(errors)
      },
    )
  }

  const deleteClicked = (type, rubric) => {
    areYouSure("warning", () => {
      APIClient.delete(`/${type.toLowerCase()}_rubrics/${rubric.id}`, () => {
        window.location.href = "/"
      })
    })
  }

  const createNewRubric = () => {
    if (!labelClicked && canCreate) {
      setLabelClicked(true)
      fetch("/implementation_rubrics/new").then((response) => {
        window.location.href = response.url
      })
    }
  }
  const calculateProgressionScore = () => {
    const parts = window.location.href.split("/")
    const idFound = parts[parts.length - 1]

    if (!idFound) return

    const findCurrentRubric = schoolAssociatedWithRubrics.find(
      (f) => f.id === parseInt(idFound, 10),
    )

    if (!findCurrentRubric || _.isNull(findCurrentRubric.results_details)) return

    const previousRecord = findPreviousMonthRecord(
      findCurrentRubric.results_details,
    )

    if (!previousRecord) return

    const previousScore = previousRecord.results.Overall
    const latestRecord = last(findCurrentRubric.results_details)

    if (!latestRecord) return

    const latestScore = latestRecord.results.Overall
    const scoreDifference = latestScore - previousScore
    setMonthlyScoreDiff(scoreDifference)
  }

  const ButtonHash = [
    // canCreate
    //   ? {
    //       textString: commonStrings.createNewRubric,
    //       backgroundColor: "#0CA4A5",
    //       color: "#FFFFFF",
    //       onClick: () => createNewRubric(),
    //     }
    //   : {},
    rubric.workflow_state === "draft" && !showListingSubmitted
      ? {
        textString: submitting ? "Submitting…" : commonStrings.generateReport,
        backgroundColor: "#A6192E",
        color: "#FFFFFF",
        onClick: () => submitClicked("implementation", rubric),
      }
      : {},
    rubric.workflow_state === "submitted" && !showListingSubmitted
      ? {
        textString: !showPlan
          ? commonStrings.currentSchoolPlan
          : "Show Report",
        backgroundColor: "#33485D",
        color: "#FFFFFF",
        onClick: () => {
          setShowPlan(!showPlan)
          setListingSubmitted(false)
        },
      }
      : {},
    rubric.workflow_state === "submitted" && !showListingSubmitted
      ? {
        textString: submitting
          ? commonStrings.unSubmitting
          : commonStrings.updateAndViewRubric,
        backgroundColor: "#ffc107",
        color: "#222529",
        onClick: () => {
          unsubmitClicked("implementation", rubric)
          setListingSubmitted(false)
        },
      }
      : {},
  ]

  const implementationCollapse = [
    {
      id: 1,
      generalColor: "#A6192E",
    },
    {
      id: 2,
      generalColor: "#33485D",
    },
    {
      id: 3,
      generalColor: "#E7A922",
    },
    {
      id: 4,
      generalColor: "#0CA4A5",
    },
  ]

  const items = [
    {
      label: (
        <div style={{ fontFamily: "Archivo Narrow" }} className="">
          {commonStrings.viewRubrics}
        </div>
      ),
      key: "0",
      onClick: () => {
        setListingSubmitted(true)
      },
    },

    {
      label: (
        <div
          style={{ fontFamily: "Archivo Narrow" }}
          className={` ${!canCreate && "disabledDropdown"}`}
        >
          {commonStrings.createNewRubric}
        </div>
      ),
      key: "1",
      onClick: () => {
        if (canCreate) {
          createNewRubric()
        }
      },
      disabled: !canCreate,
    },
  ]
  const indicatorsFor = (stage) => _.filter(rubric.indicators, (indicator) => indicator.stage === stage)

  function removeHtmlTags(inputString) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(inputString, "text/html")
    return doc.body.textContent || ""
  }

  const tabContent = (index, generalColor) => (
    <div className="tab-content">
      <div key={index}>
        <ImplementationRubricStage
          type="Implementation"
          flagRender={flagRender}
          rubric={rubric}
          setFlagRender={setFlagRender}
          generalColor={generalColor}
          firstStage={index === 0}
          indicators={indicatorsFor(rubric.template.stages[index].name)}
          disabled={rubric.workflow_state === "submitted"}
          setUpdatedRubric={setUpdatedRubric}
        />
      </div>
    </div>
  )
  const showSuccessAlert = () => {
    if (
      (rubricsDifference > 0 || monthlyScoreDiff > 0)
      && !showListingSubmitted
      && !showPlan
    ) {
      return (
        <Col xs={24} sm={24} md={24} lg={24}>
          {rubric.detailed_result && rubric.detailed_result.length > 0 ? (
            monthlyScoreDiff > 0
            && rubric.workflow_state === "submitted" && (
              <SuccessAlert
                type={type}
                diff={monthlyScoreDiff}
                rubricType="implementation"
              />
            )
          ) : (
            <SuccessAlert
              type={type}
              diff={rubricsDifference}
              rubricType="implementation"
            />
          )}
        </Col>
      )
    }
    return null
  }

  const lastUpdate = () => {
    if (
      rubric
      && Array.isArray(rubric.rubric_update_history)
      && rubric.rubric_update_history.length > 0
    ) {
      const latestUpdate = _.first(
        rubric.rubric_update_history.sort((a, b) => b.id - a.id),
      ).created_at
      if (latestUpdate) {
        setLatestUpdatedAt(latestUpdate)
      } else {
        console.error("Error: 'created_at' is undefined.")
      }
    } else {
      console.error(
        "Error: 'rubric' is invalid or 'rubric_update_history' is not a valid non-empty array.",
      )
    }
  }

  useEffect(() => {
    calculateProgressionScore()
    lastUpdate()
    // setLatestUpdatedAt(_.first(rubric.rubric_update_history).created_at)
  }, [])

  const autoGenerateReport = () => {
    const { id } = rubric
    APIClient.post(
      `/implementation_rubrics/${id}/force_update_rubric`,

      {},
      () => {
        APIClient.put(
          `/implementation_rubrics/${id}/submit`,
          {},
          () => {
            window.location.reload()
          },
          () => {},
        )
      },
    )
  }

  return isLoading ? (
    <Loader />
  ) : (
    <Row style={{ fontFamily: "Archivo Narrow" }}>
      <Row className="width50">
        <Col xs={24} sm={24} md={15} lg={15} className="px-1 headingNumbering">
          <div>
            <div>
              <span className="font-weight-bold heading-color heading-size">
                {showListingSubmitted
                  ? "Implementation Rubrics"
                  : `Implementation Rubric #${rubric.number}`}
              </span>
            </div>
            <div>
              {!showListingSubmitted && (
                <Col className="status-block" xs={24} sm={24} md={24} lg={24}>
                  <span className="status-color">
                    {rubric.workflow_state === "submitted"
                      && (showPlan && !showListingSubmitted ? (
                        "Submitted"
                      ) : (
                        <div className="d-flex custom-css-ant-tooltip">
                          <Tooltip
                            title={commonStrings.lastUpdatedDate}
                            overlayClassName="custom-css-ant-tooltip"
                          >
                            <div className="">
                              <span
                                className="lastUpdated lineHeight"
                                style={{ color: CommonColors.UA_BOLD_BLACK }}
                              >
                                <b>{commonStrings.lastUpdated}:&nbsp;</b>
                              </span>
                              <span className="lastUpdated lineHeight">
                                {!_.isEmpty(latestUpdatedAt)
                                  ? new Date(
                                    latestUpdatedAt,
                                  ).toLocaleDateString("en-US")
                                  : "N/A"}
                              </span>
                            </div>
                          </Tooltip>
                          &nbsp; &nbsp;
                          <Tooltip
                            title={commonStrings.lastSubmittedDate}
                            overlayClassName="custom-css-ant-tooltip"
                          >
                            <div className="">
                              <span
                                className="lastUpdated lineHeight"
                                style={{ color: CommonColors.UA_BOLD_BLACK }}
                              >
                                <b>{commonStrings.lastSubmitted}:&nbsp;</b>
                              </span>
                              <span className="lastUpdated lineHeight">
                                {reportDate}
                              </span>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </span>
                </Col>
              )}
            </div>
          </div>
        </Col>
        {!showListingSubmitted && (
          <Col className="status-block" xs={24} sm={24} md={4} lg={4}>
            <span className="status-color">
              {rubric.workflow_state === "draft" && rubric.workflow_state}
            </span>
          </Col>
        )}
      </Row>

      <Row className="width50">
        <Col xs={24} sm={24} md={24} lg={24} style={componentStyles.centered}>
          <span className="d-flex justify-content-end">
            {ButtonHash.filter((props) => Object.keys(props).length > 0).map(
              (props, id) => {
                const isTenureCompletedValue = props.textString === commonStrings.updateAndViewRubric
                  ? !isTenureCompleted(rubric.created_at)
                  : true
                return (
                  isTenureCompletedValue && (
                    <HeaderButtons
                      key={id}
                      id={id}
                      textString={props.textString}
                      backgroundColor={props.backgroundColor}
                      color={props.color}
                      onClick={props.onClick}
                    />
                  )
                )
              },
            )}
          </span>
          <span className="dropdown-rubric cursorPointer">
            <Dropdown
              onOpenChange={(state) => {
                setDropDownIconRotate(state)
              }}
              className="btnRubric dropdownRubricImplementation"
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <div>
                <Space>
                  <span className="fontSize14">Rubrics</span>
                  <div className="mb-1">
                    {dropDownIconRotate ? <UpOutlined /> : <DownOutlined />}
                  </div>
                </Space>
              </div>
            </Dropdown>
          </span>
        </Col>
      </Row>
      <Row>
        <Col
          id="current-school-plan-desc"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          className="pt-4 pb-2"
        >
          {rubric.workflow_state === "submitted" && !showListingSubmitted && (
            <span id="current-school-plan-desc" className="paragraph">
              {showPlan ? commonStrings.implPlan : commonStrings.implReport}
            </span>
          )}
          {rubric.workflow_state === "draft" && !showListingSubmitted && (
            <span className="paragraph">{commonStrings.implRubric}</span>
          )}
        </Col>
      </Row>
      {showListingSubmitted && (
        <Col xs={24} sm={24} md={24} lg={24} className="mt-4">
          <RubricsListing
            rubricsList={rubricsList}
            rubricType="Implementation"
          />
        </Col>
      )}
      {showSuccessAlert()}

      <Col xs={24} sm={24} md={24} lg={24}>
        {rubric.workflow_state === "submitted" && (
          <>
            {!showPlan && !showListingSubmitted && (
              <ImplementationReport
                rubric={rubric}
                rubricListing={rubricsList}
                generalColor={implementationCollapse}
                reportDate={reportDate}
                previousRubricUpdateDate={findObjectBeforeId(
                  rubricsList,
                  rubric.id,
                )}
                rubricNumber={`Implementation Rubric #${rubric.number} (${reportDate})`}
                currentschoolLogo={rubric.logo_url}
                currentSchoolName={currentSchool.name}
                dateForReport={formatTimestamp(rubricInitial.updated_at)}
                rubricType={rubricType}
              />
            )}
            {showPlan && !showListingSubmitted && (
              <ImplementationPlan
                rubric={rubric}
                reportHeadingTop={`Implementation Rubric #${rubric.number} Report  (${reportDate})`}
                removeHtmlTags={removeHtmlTags}
                currentSchoolName={currentSchool.name}
                dateForReport={formatTimestamp(rubricInitial.updated_at)}
                currentschoolLogo={rubric.logo_url}
              />
            )}
          </>
        )}
      </Col>
      {rubric.workflow_state === "draft" && !showListingSubmitted && (
        <Col xs={24} sm={24} md={24} lg={24}>
          <Space direction="vertical" size={20} style={{ display: "flex" }}>
            {implementationCollapse.map((props, index) => (
              <Collapsible
                index={index}
                flagRender={flagRender}
                tabContent={() => tabContent(index, props.generalColor)}
                id={props.id}
                title={rubric.template.stages[index].name}
                subHeading={rubric.template.stages[index].heading}
                text={rubric.template.stages[index].description}
                generalColor={props.generalColor}
                indicators={indicatorsFor(rubric.template.stages[index].name)}
                disabled={rubric.workflow_state === "submitted"}
                firstStage={index === 0}
              />
            ))}
          </Space>
          <div className="my-4">
            {rubric.workflow_state === "draft" && (
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  deleteClicked("implementation", rubric)
                }}
              >
                Delete Rubric
              </button>
            )}
          </div>
        </Col>
      )}
      <div>
        <ScrollToTop
          style={{ border: "1px solid #7E7C7D", marginBottom: "50px" }}
          component={(
            <Tooltip
              placement="top"
              title={(
                <span style={{ fontWeight: "bolder" }}>
                  {commonStrings.scrollerText}
                </span>
              )}
            >
              <div className="animate__animated animate__bounceIn scrollerToTop d">
                <UpCircleOutlined />
              </div>
            </Tooltip>
          )}
          smooth
        />
      </div>

      <Modal
        open={
          isTenureCompleted(rubric.created_at)
          && rubric.workflow_state === "draft"
        }
        closable={false}
        width={500}
        title={<div className="noSchoolModal">{commonStrings.alert}</div>}
        footer={[
          <Button
            className="noSchoolModalButton"
            onClick={() => {
              autoGenerateReport()
            }}
          >
            {commonStrings.generateReport}
          </Button>,
        ]}
        centered
      >
        <b>{commonStrings.tenureCompletionAlert}</b>
      </Modal>
    </Row>
  )
}

export default ImplementationRubric
