import React from "react"
import { Bar } from "react-chartjs-2"
import _ from "underscore"

const CustomBarChart = ({
  labels,
  datasets,
  onClick,
  max = null,
  stepSize = null,
  formatPercentage = false,
  graphUrlBase64 = null,
}) => {
  function extractStageValues(datasets) {
    const stageValues = {}
    datasets.forEach((dataset) => {
      if (!dataset || !dataset.data) {
        return
      }
      dataset.data.forEach((stageData) => {
        const { stageName, data } = stageData

        if (!stageValues[stageName]) {
          stageValues[stageName] = []
        }
        data.forEach((monthData) => {
          Object.keys(monthData).forEach((key) => {
            if (key !== "month") {
              stageValues[stageName].push({ month: monthData.month, value: monthData[key] })
            }
          })
        })
      })
    })

    return stageValues
  }
  const stageValues = extractStageValues(datasets)
  const overallDataFiltered = stageValues.Overall
    ? stageValues.Overall.filter((d) => d.value !== null)
    : []
  const latestOverall = overallDataFiltered.slice(-1)[0] ? overallDataFiltered.slice(-1)[0].value : 0
  const secondLastOverall = overallDataFiltered.slice(-2, -1)[0] ? overallDataFiltered.slice(-2, -1)[0].value : 0
  const overallIndex = 12
  const overallData = Array(labels.length).fill(0)
  const secondLastOverallData = Array(labels.length).fill(0)
  overallData[overallIndex] = latestOverall
  secondLastOverallData[overallIndex] = secondLastOverall
  const data = {
    labels,
    datasets: [
      {
        label: "Commitment Stage I",
        data: stageValues["Commitment Stage I"] ? stageValues["Commitment Stage I"].map((d) => d.value) : [],
        backgroundColor: "#a6192e",
        borderRadius: {
          topLeft: 5,
          topRight: 5,
        },
      },
      {
        label: "Commitment Stage II",
        data: stageValues["Commitment Stage II"] ? stageValues["Commitment Stage II"].map((d) => d.value) : [],
        backgroundColor: "#33485d",
        borderRadius: {
          topLeft: 5,
          topRight: 5,
        },
      },
      {
        label: "Implementation Stage I",
        data: stageValues["Implementation Stage I"] ? stageValues["Implementation Stage I"].map((d) => d.value) : [],
        backgroundColor: "#e7a922",
        borderRadius: {
          topLeft: 5,
          topRight: 5,
        },
      },
      {
        label: "Implementation Stage II",
        data: stageValues["Implementation Stage II"] ? stageValues["Implementation Stage II"].map((d) => d.value) : [],
        backgroundColor: "#0ca4a5",
        borderRadius: {
          topLeft: 5,
          topRight: 5,
        },
      },
      {
        label: "Previous Overall",
        data: secondLastOverallData,
        backgroundColor: "#b4b2b3fe",
        borderRadius: {
          topLeft: 5,
          topRight: 5,
        },
      },
      {
        label: "Latest Overall",
        data: overallData,
        backgroundColor: "#7e7c7d",
        borderRadius: {
          topLeft: 5,
          topRight: 5,
        },
      },
    ],
  }

  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    onClick: (event, element) => {
      if (element.length && onClick && element[0].datasetIndex < 4) {
        onClick(element[0].index)
      }
    },
    onHover: (event, chartElement) => {
      if (onClick) {
        event.native.target.style.cursor = chartElement[0] ? "pointer" : "default"
      }
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          filter(legendItem) {
            return legendItem.datasetIndex !== 4 && legendItem.datasetIndex !== 5
          },
        },
        onHover: (event) => {
          event.native.target.style.cursor = "pointer"
        },
        onLeave: (event) => {
          event.native.target.style.cursor = "default"
        },
      },
      tooltip: {
        enabled: _.isNull(graphUrlBase64) ? true : graphUrlBase64.length > 0,
        callbacks: {
          label: (tooltipItem) => ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}${
            formatPercentage ? "%" : ""
          }`,
        },
      },
    },
    scales: {
      y: {
        max,
        beginAtZero: true,
        ticks: {
          stepSize,
        },
      },
    },
  }

  return <Bar data={data} options={options} />
}

export default CustomBarChart
