import React from "react"
import ReactApexChart from "react-apexcharts"
import { filterNullStudents, removeUniversalTier } from "../../utilities"

const TimelineDumbbell = ({
  titleText = "",
  dumbbellColorStart = "#D9D9D9",
  dumbbellColorEnd = "#0CA4A5",
  graphData,
  setOpenModal,
  setComparisonActualCapacity,
}) => {
  const chartData = [
    {
      data: graphData,
    },
  ]

  const chartOptions = {
    chart: {
      height: 390,
      type: "rangeBar",
      zoom: {
        enabled: false,
      },
    },

    colors: ["#494949", "#494949"],
    plotOptions: {
      bar: {
        horizontal: true,
        isDumbbell: true,
        dumbbellColors: [[dumbbellColorStart, dumbbellColorEnd]],
      },
    },
    title: {
      text: titleText,
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "left",
      customLcustomLegendItemsegendItems: ["", ""],
    },
    fill: {
      type: "gradient",
      gradient: {
        gradientToColors: ["#36BDCB"],
        inverseColors: false,
        stops: [0, 100],
      },
    },

    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartOptions}
          series={chartData}
          type="rangeBar"
          height={390}
          onClick={() => {
            const values = filterNullStudents(
              removeUniversalTier(window.gon.CURRENT_SCHOOL_ACTIVITIES),
            ).length
            setComparisonActualCapacity(
              filterNullStudents(
                removeUniversalTier(window.gon.CURRENT_SCHOOL_ACTIVITIES),
              ),
            )
            if (values > 0) {
              setOpenModal(true)
            }
          }}
        />
      </div>
      <div id="html-dist" />
    </div>
  )
}

export default TimelineDumbbell
