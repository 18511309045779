import React from "react"
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Text,
  Image,
} from "@react-pdf/renderer"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"
import uaLogo from "../../../assets/images/rsp-logo.png"

Font.register({
  family: "Archivo Narrow",
  fonts: [{ src: archivoNarrow }, { src: archivoNarrowBold, fontWeight: 700 }],
})

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Archivo Narrow",
    paddingTop: 14,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  competencyItem: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 25,
    fontSize: 11,
  },
  bulletPoint: {
    paddingRight: 10,
  },
  uaLogo: {
    width: 115,
    height: 64,
    marginBottom: 20,
  },
  uaLogo1: {
    width: 115,
    height: 64,
  },
  heading: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 700,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    fontWeight: 700,
    minHeight: 40,
    width: "100%",

    borderWidth: 2,
    borderBottomWidth: 2,
    color: "white",
  },
  widthHeading: {
    width: "35%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontWeight: 700,
    fontSize: 16,
  },
  widthDescription: {
    width: "65%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontWeight: 300,
    fontSize: 12,
    paddingRight: 11,
    paddingLeft: 15,
  },
  allData: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  studentKey: {
    width: "12%",
    fontSize: 22,
    fontWeight: 700,
    textAlign: "center",
  },
  insideData: {
    padding: 20,
    width: "100%",
  },
  studentKeyData: {
    fontSize: 12,
    paddingTop: 10,
  },
  competencies: {
    fontSize: 14,
    fontWeight: 700,
  },
  adultsSel: {
    fontSize: 12,
    paddingTop: 10,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  logo: {
    width: 100,
    height: 50,
  },
})

const SELScanpdf = ({ data, matrix, currentschoolLogo }) => {
  const filterItems = (key, type) => matrix.completed_items.filter(
    (item) => item.adult === (type === "adult")
        && Object.keys(item.sel_standards).includes(key),
  )

  const getDescription = (name) => {
    let description = ""
    switch (name.trim().toLowerCase()) {
      case "self-awareness":
        description = "Recognizing who I am, what I need, and how I feel relative to the world around me."
        break
      case "self management":
        description = "Managing my behavior in pro social ways."
        break
      case "social awareness":
        description = "Demonstrating an awareness of the role and values of others in the greater community."
        break
      case "social management":
        description = "Interacting with others in meaningful and productive ways."
        break
      default:
        description = ""
        break
    }
    return description
  }
  const colors = ["#A6192E", "#33485D", "#E7A922", "#0CA4A5"]
  const colorsForDessa = [
    "#A6192E",
    "#A6192E",
    "#33485D",
    "#33485D",
    "#33485D",
    "#33485D",
    "#E7A922",
    "#E7A922",
  ]
  const isDessSelStandards = data === window.gon.DESS_SEL_STANDARDS
  return (
    <Document>
      {data.map((group, index) => {
        const colorsArray = isDessSelStandards ? colorsForDessa : colors
        const color = colorsArray[index % colorsArray.length]
        return (
          <Page size="A4" style={pdfStyles.page} key={index}>
            <View style={pdfStyles.imageContainer} fixed>
              <Image style={pdfStyles.uaLogo} src={uaLogo} />
              {currentschoolLogo && (
                <Image style={pdfStyles.uaLogo1} src={currentschoolLogo} />
              )}
            </View>
            <View style={pdfStyles.section}>
              <View
                style={[
                  pdfStyles.header,
                  {
                    backgroundColor: color,
                    borderColor: color,
                    color: "white",
                  },
                ]}
              >
                <View
                  style={[
                    pdfStyles.widthHeading,
                    {
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text>{group.name}</Text>
                </View>
                {data !== window.gon.DESS_SEL_STANDARDS ? (
                  <View
                    style={[
                      pdfStyles.widthDescription,
                      { backgroundColor: color, color: "white" },
                    ]}
                  >
                    <Text>{getDescription(group.name)}</Text>
                  </View>
                ) : null}
              </View>

              {Object.keys(group.standards).map((key) => {
                const studentItems = filterItems(key, "student")
                const adultItems = filterItems(key, "adult")
                const hasCompetencies = studentItems.length > 0
                const hasAdultCompetencies = adultItems.length > 0

                return (
                  <View key={key}>
                    <View
                      style={[
                        pdfStyles.allData,
                        {
                          fontWeight: hasCompetencies ? "700" : "400",
                          backgroundColor:
                            hasCompetencies || hasAdultCompetencies
                              ? `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.1)`
                              : "transparent",
                        },
                      ]}
                    >
                      <View style={pdfStyles.studentKey}>
                        <Text style={{ color }}>{key}</Text>
                      </View>
                      <View style={pdfStyles.insideData}>
                        <View style={pdfStyles.studentKeyData}>
                          <Text>
                            {group.standards[key].student_indicator_language}
                          </Text>
                        </View>
                        {hasCompetencies && (
                          <View style={pdfStyles.competencies}>
                            {studentItems.map((item, index) => (
                              <View
                                key={index}
                                style={pdfStyles.competencyItem}
                              >
                                <Text style={pdfStyles.bulletPoint}>•</Text>
                                <Text>
                                  {item.activity} ({item.category},{" "}
                                  {item.tier_of_support}, {item.length_of_time})
                                </Text>
                              </View>
                            ))}
                          </View>
                        )}
                        <View
                          style={[
                            pdfStyles.adultsSel,
                            {
                              fontWeight: hasAdultCompetencies ? "700" : "400",
                            },
                          ]}
                        >
                          <Text>
                            {group.standards[key].adult_indicator_language}
                          </Text>
                          {adultItems.some(
                            (item) => item.category === "Adult SEL",
                          ) && (
                            <View style={pdfStyles.competencies}>
                              {adultItems.map((item, index) => (
                                <View
                                  key={index}
                                  style={pdfStyles.competencyItem}
                                >
                                  <Text style={pdfStyles.bulletPoint}>•</Text>
                                  <Text>
                                    {item.activity} ({item.category},{" "}
                                    {item.tier_of_support},{" "}
                                    {item.length_of_time})
                                  </Text>
                                </View>
                              ))}
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                )
              })}
            </View>
          </Page>
        )
      })}
    </Document>
  )
}

export default SELScanpdf
