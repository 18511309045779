import React, { useState, useEffect } from "react"
import { Table } from "ant-table-extensions"
import {
  Card,
  Button,
  Row,
  Col,
  Tooltip,
  Progress,
  Tag,
  Popover,
  Drawer,
} from "antd"
import _ from "underscore"
import { areYouSure, TruncateText } from "../../utilities"
import commonStrings from "../../commonStrings"
import componentStyles from "../../styles"

function ActivityLogsView({
  title: category,
  activities,
  completedActivities,
  totalActivities,
  dataYml,
  socialData,
  behaviorSupports,
  extracurricularActivity,
  adultsel,
  matrix1,
}) {
  const [messageText, setMessageText] = useState("")
  const [popoversContent, setPopoversContent] = useState({})
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const keyPairColorHash = {
    "Instructional Practices": {
      customColor: "#A6192E",
    },
    "Social Emotional Supports": {
      customColor: "#33485D",
    },
    "Behavior Supports": {
      customColor: "#E7A922",
    },
    "Extracurricular Activities": {
      customColor: "#0CA4A5",
    },
    "Adult SEL": {
      customColor: "#7E7C7D",
    },
  }

  const createItem = (tagName, description = null) => {
    APIClient.post(
      "/program_matrix/program_matrix_items",
      { category, description },
      (response) => {
        const urlParams = new URLSearchParams(window.location.search)

        if (tagName) {
          urlParams.set("tagName", tagName)
        }
        window.location.href = `/program_matrix/new?${
          response.category
        }&activityId=${response.id}&mode=add&${urlParams.toString()}`
      },
    )
    // window.location.reload()
  }

  const getDefinition = async (tagName, category) => {
    switch (category) {
      case "Instructional Practices":
        return (
          dataYml.instructional_practices.find(
            (practice) => practice.name === tagName,
          ).description || commonStrings.practiceNotFound
        )

      case "Social Emotional Supports":
        return (
          socialData.social_emotional_support.find(
            (support) => support.name === tagName,
          ).description || commonStrings.practiceNotFound
        )

      case "Behavior Supports":
        return (
          behaviorSupports.behavior_supports.find(
            (support) => support.name === tagName,
          ).description || commonStrings.practiceNotFound
        )

      case "Extracurricular Activities":
        return (
          extracurricularActivity.extracurricular.find(
            (activity) => activity.name === tagName,
          ).description || commonStrings.practiceNotFound
        )

      case "Adult SEL":
        return (
          adultsel.adult.find((activity) => activity.name === tagName)
            .description || commonStrings.practiceNotFound
        )

      default:
        return commonStrings.practiceNotFound
    }
  }

  const handleTagPopover = async (tagName, category) => {
    if (!popoversContent[tagName]) {
      try {
        setPopoversContent((prev) => ({ ...prev, [tagName]: "Loading..." }))

        const definition = await getDefinition(tagName, category)

        const popoverContent = (
          <>
            <div>{definition}</div>
            <Button
              onClick={() => createItem(tagName, definition)}
              className="createActivity"
              style={{
                backgroundColor: `${keyPairColorHash[category].customColor}`,
              }}
            >
              {commonStrings.createActivity}
            </Button>
          </>
        )

        setPopoversContent((prev) => ({
          ...prev,
          [tagName]: popoverContent,
        }))
      } catch (error) {
        console.error("Error:", error)
        setPopoversContent((prev) => ({
          ...prev,
          [tagName]: "Error loading content",
        }))
      }
    }
  }

  const renderTags = (tagsData, category) => {
    const visibleTags = category === "Adult SEL" ? tagsData.slice(0, 7) : tagsData.slice(0, 6)
    const remainingTags = category === "Adult SEL" ? tagsData.slice(7) : tagsData.slice(6)
    return (
      <>
        <div>
          {visibleTags.map((tag, index) => (
            <Popover
              key={tag.number}
              className="poping-over"
              content={popoversContent[tag.name] || ""}
              title={commonStrings.definition}
              trigger="hover"
              onVisibleChange={() => handleTagPopover(tag.name, category)}
              placement="top"
            >
              <Tag
                color={
                  index % 2 === 0
                    ? `${keyPairColorHash[category].customColor}33`
                    : "#7E7C7D33"
                }
              >
                {tag.name}
              </Tag>
            </Popover>
          ))}
        </div>

        {category !== "Adult SEL" && tagsData.length > 6 && (
          <Button
            className="see-more"
            onClick={showDrawer}
            style={{
              backgroundColor: `${keyPairColorHash[category].customColor}`,
            }}
          >
            {commonStrings.seeMore}
          </Button>
        )}
        <div>
          <Drawer
            title={
              category === "Instructional Practices"
                ? "Instructional Practices Activities"
                : category === "Social Emotional Supports"
                  ? "Social Emotional Support Activities"
                  : category === "Behavior Supports"
                    ? "Behavior Support Activities"
                    : category === "Extracurricular Activities"
                      ? "Extracurricular Activities"
                      : category === "Adult SEL"
                        ? "Adult SEL Activities"
                        : ""
            }
            className="program-matrix-drawer"
            placement="left"
            onClose={onClose}
            open={open}
            visible={showDrawer}
            width={350}
            destroyOnClose
            mask
            maskClosable
          >
            {remainingTags.map((tag, index) => (
              <Popover
                key={tag.number}
                content={popoversContent[tag.name] || ""}
                title={commonStrings.definition}
                trigger="hover"
                onVisibleChange={() => handleTagPopover(tag.name, category)}
                className="remaining-tags"
                placement="bottom"
              >
                <Tag
                  color={
                    index % 2 === 0
                      ? `${keyPairColorHash[category].customColor}33`
                      : "#7E7C7D33"
                  }
                >
                  {tag.name}
                </Tag>
              </Popover>
            ))}
          </Drawer>
        </div>
      </>
    )
  }
  const [count] = useState(commonStrings.maximumLength - completedActivities)

  useEffect(() => {
    if (totalActivities < 1) {
      setMessageText("Please add at least 5 activities")
    } else if (
      completedActivities > 0
      && totalActivities < 5
      && totalActivities > completedActivities
    ) {
      if (totalActivities - completedActivities === 1) {
        setMessageText(
          `Please complete ${
            totalActivities - completedActivities
          } activity & add ${5 - totalActivities} more`,
        )
      } else {
        setMessageText(
          `Please complete ${
            totalActivities - completedActivities
          } activities & add ${5 - totalActivities} more `,
        )
      }
    } else if (
      totalActivities < 5
      && totalActivities - completedActivities === 0
      && completedActivities > 0
    ) {
      if (completedActivities === 4) {
        setMessageText(`Please add ${5 - completedActivities} more activity`)
      } else {
        setMessageText(`Please add ${5 - completedActivities} more activities`)
      }
    } else if (
      completedActivities > 0
      && completedActivities < 5
      && totalActivities >= 5
    ) {
      if (completedActivities === 4) {
        setMessageText("Please complete 1 more activity")
      } else {
        setMessageText(
          `Please complete ${5 - completedActivities} more activities`,
        )
      }
    } else if (completedActivities === 0 && totalActivities >= 1) {
      if (totalActivities < 5) {
        if (totalActivities === 1) {
          setMessageText(
            `Please complete ${totalActivities} activity & add ${
              5 - totalActivities
            } more`,
          )
        } else {
          setMessageText(
            `Please complete ${totalActivities} activities & add ${
              5 - totalActivities
            } more`,
          )
        }
      } else {
        setMessageText("Please complete 5 activities")
      }
    }
  }, [])

  const deleteItem = (id) => {
    areYouSure("warning", () => {
      APIClient.delete(`/program_matrix/program_matrix_items/${id}`, () => {
        window.location.reload()
      })
    })
  }

  const tableColumns = [
    {
      title: "Activity Name",
      dataIndex: "activity",
      align: "center",
      render: (_, record) => (
        <div className="d-flex text-decoration-none">
          <Tooltip placement="top" title={record.activity}>
            {record.activity ? (
              TruncateText(record.activity, 55)
            ) : (
              <span style={componentStyles.activityTitle}>
                {commonStrings.addTitle}
              </span>
            )}
          </Tooltip>
        </div>
      ),

      width: "60%",
    },
    {
      title: "Status",
      dataIndex: "is_completed",
      align: "center",
      render: (_, record) => (
        <div className="d-flex justify-content-center text-decoration-none">
          {record.is_completed ? "Completed" : "Incomplete"}
        </div>
      ),
      width: "20%",
    },

    {
      title: "Actions",
      dataIndex: "",
      align: "center",
      render: (index, record) => (
        <div className="d-flex justify-content-around deleteImplementationRubric">
          <Tooltip
            title={
              category === "Adult SEL"
                ? false
                : _.isNull(matrix1.student_population)
                  ? "Please add student population"
                  : ""
            }
          >
            <a
              onClick={() => {
                if (
                  category === "Adult SEL"
                  || !_.isNull(matrix1.student_population)
                ) {
                  window.location.href = `/program_matrix/new?${category}&activityId=${record.id}&mode=edit`
                }
              }}
              className={`text-dark ${
                category === "Adult SEL"
                || !_.isNull(matrix1.student_population)
                  ? ""
                  : "cursorNotAllowed"
              }`}
            >
              {commonStrings.edit}
            </a>
          </Tooltip>
          {/* window.location.href = `/program_matrix/new?${category}&activityId=${response.id}` */}
          <a onClick={() => deleteItem(record.id)} className="text-dark">
            {commonStrings.del}
          </a>
        </div>
      ),
      width: "20%",
    },
  ]
  useEffect(() => {
    $("[data-toggle=\"popover\"]").each(function () {
      const $this = $(this)
      $this.popover({
        html: true,
        trigger: "hover",
        placement: "top",
        container: "body",
        delay: {
          hide: 1000,
        },
      })
    })
  }, [])
  const popovers = {
    "Instructional Practices":
      "Instructional Practices are the student learning activities that are prioritized across the community (e.g., Turn and Talk; <a href='https://drive.google.com/file/d/1P9oVhow7UcwXoYAU1_DarEIvLI58phtY/view' target='_blank' rel='noopener'>more examples here</a>).",

    "Social Emotional Supports":
      "Social Emotional Supports are designed to help students interact effectively with themselves and others in the pursuit of constructive problem-solving (e.g., counseling and mentoring).",

    "Behavior Supports":
      "Behavioral Supports are designed to provide a safe and predictable environment for students (e.g., restorative conversations).",

    "Extracurricular Activities":
      "Extracurricular Activities are the student experiences outside of the core curriculum (e.g., clubs, arts and athletics).",

    "Adult SEL":
      "Adult SEL is the result of experiences and activities that develop social-emotional skills in adults (e.g., teacher teams, professional development, and staff community builders).",
  }

  return (
    <div>
      <Card
        className="card mb-20"
        title={(
          <Row
            gutter={16}
            justify="flex-start"
            style={componentStyles.rowStart}
          >
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              style={componentStyles.colCenterStart}
            >
              <span
                style={{ color: `${keyPairColorHash[category].customColor}` }}
                data-toggle="popover"
                data-content={`<h6 class='mb-0'>${
                  popovers[`${category}`]
                }</h6>`}
              >
                {category}
              </span>

              <Progress
                percent={completedActivities * 20}
                strokeColor={keyPairColorHash[category].customColor}
                format={(percent) => `${percent} %`}
                type="circle"
              />
            </Col>
            {completedActivities < commonStrings.maximumLength && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                style={componentStyles.colCenter}
              >
                {count > 0 && (
                  <span className="messageText">{messageText}</span>
                )}
              </Col>
            )}
          </Row>
        )}
        extra={(
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Tooltip
                className="btn-activity"
                title={
                  category === "Adult SEL"
                    ? false
                    : _.isNull(matrix1.student_population)
                      ? "Please add student population"
                      : ""
                }
              >
                <div>
                  <Button
                    key={
                      category === "Adult SEL"
                        ? false
                        : _.isNull(matrix1.student_population)
                    }
                    disabled={
                      category === "Adult SEL"
                        ? false
                        : _.isNull(matrix1.student_population)
                    }
                    onClick={() => createItem()}
                    block
                    style={{
                      backgroundColor: `${keyPairColorHash[category].customColor}`,
                    }}
                    className="btn-activity"
                  >
                    {commonStrings.addActivity}
                  </Button>
                </div>
              </Tooltip>
            </Col>
          </Row>
        )}
      >
        <Table
          rowClassName={(record, index) => (index % 2 === 0
            ? `table-row-${category.replaceAll(" ", "_").toLowerCase()}`
            : "table-row-light")}
          pagination={{ pageSize: 5, showSizeChanger: false }}
          searchable={false}
          bordered
          columns={tableColumns}
          dataSource={activities}
          rowKey="id"
        />
      </Card>
      {!(category === "Adult SEL"
        ? false
        : _.isNull(matrix1.student_population)) && (
        <div
          className="pop-over"
          style={{ width: "100%", display: "flex", alignItems: "baseline" }}
        >
          {(() => {
            switch (category) {
              case "Social Emotional Supports":
                return renderTags(
                  socialData.social_emotional_support,
                  category,
                )
              case "Behavior Supports":
                return renderTags(behaviorSupports.behavior_supports, category)
              case "Extracurricular Activities":
                return renderTags(
                  extracurricularActivity.extracurricular,
                  category,
                )
              case "Adult SEL":
                return renderTags(adultsel.adult, category)
              case "Instructional Practices":
                return renderTags(dataYml.instructional_practices, category)
              default:
                return null
            }
          })()}
        </div>
      )}
    </div>
  )
}

export default ActivityLogsView
