import React, { useEffect, useState } from "react"
import {
  Row, Col, Divider, Select,
} from "antd"
import VideoTabs from "../VideoTabs"
import {
  countAttributes,
  filterRecordsById,
  sortOnCategory,
} from "../../utilities"
import VideoCard from "../VideoCard"
import VideoModal from "../VideoModal"

const VideoResources = () => {
  const [videosArray, setVideosArray] = useState([])
  const [tabButtons, setTabButtons] = useState([])
  const [activeButton, setActiveButton] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [clickedVideoObject, setClickedVideoObject] = useState({})
  const [filteredData, setFilteredData] = useState([])

  const [selectedCategory, setSelectedCategory] = useState("")

  const initialCallForVideos = () => {
    APIClient.get("/video_resources/video", (response) => {
      setVideosArray(response)
    })
  }

  const getFilteredVideos = () => {
    setFilteredData(filterRecordsById(activeButton, videosArray))
  }

  const returnCategorizedData = (selectedCategory, array) => {
    setFilteredData(sortOnCategory(selectedCategory, array))
    setTabButtons(
      countAttributes(sortOnCategory(selectedCategory, videosArray)),
    )
  }

  useEffect(() => {
    initialCallForVideos()
  }, [])

  useEffect(() => {
    if (videosArray.length > 0) {
      setTabButtons(countAttributes(videosArray))
    }
  }, [videosArray, selectedCategory, activeButton])

  useEffect(() => {
    if (videosArray.length > 0) {
      getFilteredVideos()
    }
  }, [videosArray, activeButton])

  useEffect(() => {
    if (selectedCategory) {
      returnCategorizedData(
        selectedCategory,
        filterRecordsById(activeButton, videosArray),
      )
    } else {
      getFilteredVideos()
    }
  }, [selectedCategory, activeButton])

  const categoryArray = [
    { value: 1, label: "Sustainability" },
    { value: 2, label: "Implementation" },
    { value: 3, label: "SEL Scan" },
    { value: 4, label: "DESSA Report" },
    { value: 5, label: "SEL Quality" },
    { value: 6, label: "Activity Details" },
    { value: 7, label: "N/A" },
  ]

  return (
    <Row justify="center" className="deployedFontFamily">
      <VideoModal
        openStatus={openModal}
        setOpenStatus={setOpenModal}
        clickedVideoObject={clickedVideoObject}
      />
      <Col xs={24} sm={24} md={24} lg={20} className="d-flex">
        {tabButtons.map((record, index) => (
          <VideoTabs
            key={index}
            title={record.title}
            count={record.count}
            isSelected={activeButton}
            setIsSelected={setActiveButton}
            id={record.id}
          />
        ))}
      </Col>
      <Col xs={24} sm={24} md={24} lg={20}>
        <Divider />
      </Col>
      <Col xs={24} sm={24} md={24} lg={20} className="mb-3">
        <div className="video-select">
          <Select
            allowClear
            style={{ width: "20%", borderRadius: "9px" }}
            placeholder="Select Name Section"
            options={categoryArray}
            onSelect={(singleValue, object) => {
              setSelectedCategory(object.label)
            }}
            onClear={() => {
              setSelectedCategory("")
            }}
          />
        </div>
      </Col>

      <Col xs={24} sm={24} md={24} lg={20}>
        <VideoCard
          filteredData={filteredData}
          setOpenModal={setOpenModal}
          setClickedVideoObject={setClickedVideoObject}
        />
      </Col>
    </Row>
  )
}
export default VideoResources
