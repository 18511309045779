import React, { useEffect } from "react"
import ImplementationImage from "../../assets/images/dashboard-implementation.png"
import SustainabilityImage from "../../assets/images/dashboard-sustainability.png"
import ResourcesImage from "../../assets/images/dashboard-resources.png"
import BadgeImage from "../../assets/images/dashboard-badge.png"
import MatrixImage from "../../assets/images/dashboard-matrix.png"
import OverallImage from "../../assets/images/dashboard-overall.png"
import {
  uaDarkBlue, uaGold, uaRed, uaTeal,
} from "../enums"
import ProgressBar from "./charts/ProgressBar"

const Dashboard = ({
  matrixProgress,
  implementationProgress,
  sustainabilityProgress,
  matrixHref,
  implementationHref,
  sustainabilityHref,
  certified,
}) => {
  useEffect(() => {
    $("[data-toggle=\"tooltip\"]").tooltip()
  }, [])

  const overallProgress = (matrixProgress + implementationProgress + sustainabilityProgress) / 3

  return (
    <>
      <div className="row mb-5">
        <Widget
          title="Matrix"
          description="Organize activities contributing to SEL at least 2x every year"
          src={MatrixImage}
          color={uaTeal}
          href={matrixHref}
          progress={matrixProgress}
          tooltip={`Average of SEL indicators with universal activities for students: ${matrixProgress.toFixed(
            0,
          )}%`}
        />
        <Widget
          title="Implementation Rubric"
          description="Plan and execute as you update your rubric throughout the year"
          src={ImplementationImage}
          color={uaDarkBlue}
          href={implementationHref}
          progress={implementationProgress}
          tooltip={`Last submitted rubric score: ${implementationProgress.toFixed(
            0,
          )}%`}
        />
        <Widget
          title="Sustainability Rubric"
          description="Codify your solutions to strengthen your SEL program each year"
          src={SustainabilityImage}
          color={uaGold}
          href={sustainabilityHref}
          progress={sustainabilityProgress}
          tooltip={`Last submitted rubric score: ${sustainabilityProgress.toFixed(
            0,
          )}%`}
        />
      </div>
      <div className="row mb-4">
        <div className="col-4 text-center">
          <img src={OverallImage} height="60px" className="mb-4" />
          <div className="mb-2 px-5">
            <ProgressBar
              color={uaRed}
              progress={overallProgress}
              tooltip={`Average of the three SEL rubrics: ${overallProgress.toFixed(
                0,
              )}%`}
            />
          </div>
          <h3 className="text-ua-gray">Overall SEL Quality</h3>
        </div>
        <div className="col-4 text-center">
          <a href="/resources" className="text-decoration-none">
            <img src={ResourcesImage} height="60px" className="mb-3" />
            <h3 className="text-ua-gray">Resources</h3>
          </a>
        </div>
        <div className="col-4 text-center">
          <img
            src={BadgeImage}
            height="100px"
            className="mb-3"
            style={{ opacity: certified ? "1" : "0.2" }}
            title={
              certified
                ? null
                : "Reach out to Brandon Frame at bframe@urbanassembly.org to get certified."
            }
            data-toggle="tooltip"
            data-placement="bottom"
          />
        </div>
      </div>
    </>
  )
}
function Widget({
  title, description, src, color, href, progress, tooltip,
}) {
  return (
    <div className="col-4 text-center">
      <a href={href} className="text-decoration-none">
        <img src={src} height="90px" className="mb-3" />
        <h3 style={{ color: `rgb(${color})` }}>{title}</h3>
        <div className="px-5">
          <p className="text-dark">{description}</p>
          <ProgressBar progress={progress} color={color} tooltip={tooltip} />
        </div>
      </a>
    </div>
  )
}

export default Dashboard
