import React from "react"
import {
  Page,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer"
import archivoNarrow from "../../../assets/fonts/ArchivoNarrow-Regular.ttf"
import archivoNarrowBold from "../../../assets/fonts/ArchivoNarrow-Bold.ttf"
import uaLogo from "../../../assets/images/rsp-logo.png"

Font.register({
  family: "Archivo Narrow",
  fonts: [{ src: archivoNarrow }, { src: archivoNarrowBold, fontWeight: 700 }],
})

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Archivo Narrow",
    paddingTop: 14,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  uaLogo: {
    width: 115,
    height: 64,
  },
  uaLogo1: {
    width: 115,
    height: 64,
  },
  imageContainers: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },
})
const SELQualitypdf = ({ graphUrls, currentschoolLogo }) => {
  const isValidImage = (src) => src && src.startsWith("data:image/png;base64,")
  const hasValidImages = (keys) => keys.some((key) => isValidImage(graphUrls[key]))
  return (
    <Document>
      {hasValidImages(["graphUrlBase64", "graphUrlBase65", "graphUrlBase66"]) && (
      <Page size="A4" style={pdfStyles.page}>
        <View style={pdfStyles.imageContainers} fixed>
          <Image style={pdfStyles.uaLogo} src={uaLogo} />
          {currentschoolLogo && (
          <Image style={pdfStyles.uaLogo1} src={currentschoolLogo} />
          )}
        </View>
        <View style={pdfStyles.imageContainer}>
          {isValidImage(graphUrls.graphUrlBase64) && (
          <Image src={graphUrls.graphUrlBase64} style={pdfStyles.image} />
          )}
        </View>
      </Page>
      )}
      {hasValidImages(["graphUrlBase65", "graphUrlBase66"]) && (
        <Page size="A4" style={pdfStyles.page}>
          <View style={pdfStyles.imageContainer}>
            {isValidImage(graphUrls.graphUrlBase65) && (
              <Image src={graphUrls.graphUrlBase65} style={pdfStyles.image} />
            )}
            {isValidImage(graphUrls.graphUrlBase66) && (
              <Image src={graphUrls.graphUrlBase66} style={pdfStyles.image} />
            )}
          </View>
        </Page>
      )}
      {hasValidImages(["graphUrlBase68", "graphUrlBase69"]) && (
        <Page size="A4" style={pdfStyles.page}>
          <View style={pdfStyles.imageContainer}>
            {isValidImage(graphUrls.graphUrlBase68) && (
              <Image src={graphUrls.graphUrlBase68} style={pdfStyles.image} />
            )}
            {isValidImage(graphUrls.graphUrlBase69) && (
              <Image src={graphUrls.graphUrlBase69} style={pdfStyles.image} />
            )}
          </View>
        </Page>
      )}
      {hasValidImages(["graphUrlBase70", "graphUrlBase71"]) && (
        <Page size="A4" style={pdfStyles.page}>
          <View style={pdfStyles.imageContainer}>
            {isValidImage(graphUrls.graphUrlBase70) && (
              <Image src={graphUrls.graphUrlBase70} style={pdfStyles.image} />
            )}
            {isValidImage(graphUrls.graphUrlBase71) && (
              <Image src={graphUrls.graphUrlBase71} style={pdfStyles.image} />
            )}
          </View>
        </Page>
      )}
    </Document>
  )
}

export default SELQualitypdf
