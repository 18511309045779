import React, { useEffect, useState } from "react"
import _ from "underscore"
import { useDebounceEffect } from "ahooks"
import TextareaAutosize from "react-textarea-autosize"
import { areYouSure } from "../../utilities"
import commonStrings from "../../commonStrings"

function ProgramMatrixItem({
  item: initialItem,
  disabled,
  canDelete,
  removeItem,
}) {
  const [item, setItem] = useState(initialItem)
  useDebounceEffect(
    () => {
      if (item !== initialItem) {
        showSaving()
        APIClient.put(
          ` ${item.id}`,
          {
            activity: item.activity,
            description: item.description,
            tier_of_support: item.tier_of_support,
            length_of_time: item.length_of_time,
            data_collection: item.data_collection,
            sel_standards: item.sel_standards,
          },
          () => {
            hideSaving()
          },
        )
      }
    },
    [item],
    { wait: 250 },
  )

  useEffect(() => {
    $("[data-toggle=\"tooltip\"]").tooltip()
  })

  const handleOnChange = (e) => {
    const newItem = { ...item }
    switch (e.target.name) {
      case "sel_standards":
        if (_.contains(_.keys(newItem.sel_standards), e.target.value)) {
          newItem.sel_standards = _.omit(newItem.sel_standards, e.target.value)
        } else {
          newItem.sel_standards[e.target.value] = {
            connection_type: item.adult ? "promotes" : "",
          }
        }
        break
      case "connection_type":
      case "connection_explanation":
        newItem.sel_standards[e.target.getAttribute("data-sel-standard-key")][
          e.target.name
        ] = e.target.value
        break
      default:
        newItem[e.target.name] = e.target.value
    }
    setItem(newItem)
  }

  const activityInput = () => (
    <div className="form-group">
      <input
        className="form-control"
        type="text"
        placeholder={
          item.adult
            ? commonStrings.adultActivity
            : commonStrings.studentActivity
        }
        name="activity"
        disabled={disabled}
        value={item.activity || ""}
        onChange={handleOnChange}
      />
    </div>
  )

  const descriptionInput = () => {
    if (item.activity) {
      return (
        <div className="form-group">
          <TextareaAutosize
            className="form-control"
            placeholder={commonStrings.description}
            name="description"
            disabled={disabled}
            value={item.description || ""}
            onChange={handleOnChange}
          />
        </div>
      )
    }
    return null
  }

  const tierOfSupportInput = () => {
    if (item.activity && item.description) {
      return (
        <div>
          <div className="form-group">
            <select
              className="form-control"
              name="tier_of_support"
              disabled={disabled}
              value={item.tier_of_support || ""}
              onChange={handleOnChange}
            >
              <option value="" disabled>
                {commonStrings.selectTier}
              </option>
              {window.gon.TIERS_OF_SUPPORT.map((tier, index) => (
                <option key={index} value={tier}>
                  {tier}
                </option>
              ))}
            </select>
          </div>
          <div className="hint">
            <ul>
              <li>
                <span className="font-weight-bold">
                  {commonStrings.universal}
                </span>
                :{" "}
                {item.adult
                  ? commonStrings.educatorsAccess
                  : commonStrings.studentsAccess}
              </li>
              <li>
                <span className="font-weight-bold">
                  {commonStrings.targeted}
                </span>
                :{" "}
                {item.adult
                  ? commonStrings.educatorsStrategicCohorts
                  : commonStrings.RiskFactorToGain}
              </li>
              <li>
                <span className="font-weight-bold">
                  {commonStrings.tertiary}
                </span>
                :{" "}
                {item.adult
                  ? commonStrings.educatorsIndividualizedSupports
                  : commonStrings.highRiskStudentsIndividualizedSupports}
              </li>
            </ul>
          </div>
        </div>
      )
    }
    return null
  }

  const lengthOfTimeInput = () => {
    if (item.activity && item.description && item.tier_of_support) {
      return (
        <div className="form-group">
          <select
            className="form-control"
            name="length_of_time"
            disabled={disabled}
            value={item.length_of_time || ""}
            onChange={handleOnChange}
          >
            <option value="" disabled>
              {commonStrings.howLong}
            </option>
            {window.gon.LENGTHS_OF_TIME.map((length, index) => (
              <option key={index} value={length}>
                {length}
              </option>
            ))}
          </select>
        </div>
      )
    }
    return null
  }

  const dataCollectionInput = () => {
    if (
      item.activity
      && item.description
      && item.tier_of_support
      && item.length_of_time
    ) {
      return (
        <div className="form-group">
          <TextareaAutosize
            className="form-control"
            placeholder={commonStrings.dataCollected}
            name="data_collection"
            disabled={disabled}
            value={item.data_collection || ""}
            onChange={handleOnChange}
          />
        </div>
      )
    }
    return null
  }

  const standards = (key) => {
    const hash = {}
    _.pluck(window.gon.SEL_STANDARDS, "standards").forEach((array) => {
      _.extend(hash, array)
    })
    return key
      ? hash[key][
        item.adult ? "adult_indicator_language" : "student_indicator_language"
      ]
      : hash
  }

  const selStandardsOptions = () => _.without(_.keys(standards()), ..._.keys(item.sel_standards)).map((key) => (
    <option key={key} value={key}>
      {key}. {standards(key)}
    </option>
  ))

  const selStandardsInput = () => {
    if (
      item.activity
      && item.description
      && item.tier_of_support
      && item.length_of_time
    ) {
      return (
        <div>
          <div className="form-group">
            <select
              className="form-control"
              name="sel_standards"
              value=""
              disabled={disabled}
              onChange={handleOnChange}
            >
              <option value="" disabled>
                {commonStrings.selectUpto3}
              </option>
              {selStandardsOptions()}
            </select>
          </div>
          <p className="hint">{commonStrings.ComprehensiveSel}</p>
        </div>
      )
    }
    return null
  }

  const removeStandard = (key) => {
    areYouSure("warning", () => {
      handleOnChange({ target: { name: "sel_standards", value: key } })
    })
  }

  const connectionsInputs = () => _.keys(item.sel_standards)
    .sort()
    .map((key) => (
      <div key={key} className="sel-standard py-3">
        <h5>
          {key}. {standards(key)}
          {!disabled && (
          <i
            className="fa fa-trash-o ml-2"
            onClick={() => removeStandard(key)}
          />
          )}
        </h5>
        {!item.adult && (
          <>
            <div className="form-group">
              <select
                className="form-control"
                name="connection_type"
                value={item.sel_standards[key].connection_type || ""}
                disabled={disabled}
                data-sel-standard-key={key}
                onChange={handleOnChange}
              >
                <option value="" disabled>
                  {commonStrings.selectOne}
                </option>
                <option value="promotes">{commonStrings.promotes}</option>
                <option value="reinforces">{commonStrings.reinforces}</option>
              </select>
            </div>
            <div className="hint">
              <ul>
                <li>
                  <span className="font-weight-bold">
                    {commonStrings.promotes}
                  </span>
                  : {commonStrings.socialEmotional}
                </li>
                <li>
                  <span className="font-weight-bold">
                    {commonStrings.reinforces}
                  </span>
                  :{commonStrings.socialEmotionalExperience}
                </li>
              </ul>
            </div>
          </>
        )}
        {item.sel_standards[key].connection_type && (
        <div className="form-group">
          <TextareaAutosize
            className="form-control"
            name="connection_explanation"
            disabled={disabled}
            value={
                  item.sel_standards[key].connection_explanation
                  || `${item.activity} ${
                    item.sel_standards[key].connection_type
                  } ${standards(key)} by`
                }
            data-sel-standard-key={key}
            onChange={handleOnChange}
          />
        </div>
        )}
      </div>
    ))

  const deleteItem = () => {
    areYouSure("warning", () => {
      APIClient.delete(
        `/program_matrix/program_matrix_items/${item.id}`,
        () => {
          removeItem(item)
        },
      )
    })
  }

  return (
    <div
      className="item p-4"
      style={{
        borderTopLeftRadius:
          item.category === window.gon.CATEGORIES[0] ? 0 : "",
      }}
    >
      {activityInput()}
      {descriptionInput()}
      {tierOfSupportInput()}
      {lengthOfTimeInput()}
      {dataCollectionInput()}
      {selStandardsInput()}
      {connectionsInputs()}
      {canDelete && (
        <i
          className="fa fa-trash-o"
          data-toggle="tooltip"
          title="Delete item"
          onClick={deleteItem}
        />
      )}
    </div>
  )
}

export default ProgramMatrixItem
