import React from "react"
import { Row, Col, Tag } from "antd"
import { DatabaseFilled } from "@ant-design/icons"
import { TruncateText } from "../utilities"
import componentStyles from "../styles"
import commonStrings from "../commonStrings"

const VideoCard = ({ filteredData, setOpenModal, setClickedVideoObject }) => (
  <Row gutter={[8, 16]}>
    {filteredData && filteredData.length > 0 ? (
      filteredData.map((video, index) => (
        <Col xs={24} sm={24} md={24} lg={8} key={index}>
          <iframe
            title="video"
            width="360"
            height="220"
            src={
              video.youtube_link
                ? `https://www.youtube.com/embed/${new URL(
                  video.youtube_link,
                ).searchParams.get("v")}`
                : video.video.url
            }
            frameBorder="0"
            allowFullScreen
            aria-label={video.name} // Add aria-label for accessibility
          />
          <div
            onClick={() => {
              setClickedVideoObject(video)
              setOpenModal(true)
            }}
          >
            <div>
              {video.name_section.length > 0 ? (
                <>
                  {video.name_section.slice(0, 2).map((record, index) => (
                    <Tag
                      key={index}
                      className="my-1"
                      style={componentStyles.tagsStylesforCard}
                    >
                      {record}
                    </Tag>
                  ))}
                  {video.name_section.length > 2 && (
                    <Tag className="my-1" style={componentStyles.tagMoreStylesforCard}>
                      +{video.name_section.length - 2} more
                    </Tag>
                  )}
                </>
              ) : (
                "N/A"
              )}
            </div>

            <div style={componentStyles.titleSizeforCard}>
              <span>{TruncateText(video.name, 30)}</span>
            </div>
            <div style={componentStyles.headingSizeforCard}>
              <span>{TruncateText(video.description, 120)}</span>
            </div>
          </div>
        </Col>
      ))
    ) : (
      <Col xs={24} sm={24} md={24} lg={24}>
        <div>
          <div className="no-data">
            <DatabaseFilled />
          </div>
          <div className="no-activity mt-2">
            <span>{commonStrings.noVideoUploaded}</span>
          </div>
        </div>
      </Col>
    )}
  </Row>
)

export default VideoCard
