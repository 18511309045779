import React from "react"
import Select from "react-select"
import _ from "underscore"

function ProgramMatrixFilters({
  filters,
  setFilters,
  activities,
  indicators,
  isDessaReport,
}) {
  // options={window.gon.DESS_SEL_STANDARDS.map((item) => ({label: item.name,value: item.name}))}
  const handleChange = (name, value) => {
    filters[name] = value.map((object) => object.value)
    setFilters({ ...filters })
  }
  function convertValuesIntoFilters(arr) {
    const result = []
    if (result.length === 0 || result.includes(result)) {
      arr.forEach((item) => {
        const label = item.name
        const value = Object.keys(item.standards)
        result.push({ label, value })
      })
    }

    return result
  }

  const categories = () => (
    <div>
      <Select
        name="categories"
        isMulti
        defaultValue={filters.categories}
        onChange={(option) => handleChange("categories", option)}
        placeholder="Domains…"
        options={window.gon.CATEGORIES.map((category) => ({
          label: category,
          value: category,
        }))}
      />
    </div>
  )

  const tiersOfSupport = () => (
    <div>
      <Select
        name="tiers_of_support"
        isMulti
        defaultValue={filters.tiers_of_support}
        onChange={(option) => handleChange("tiers_of_support", option)}
        placeholder="Tiers of support…"
        options={window.gon.TIERS_OF_SUPPORT.map((tier) => ({
          label: tier,
          value: tier,
        }))}
      />
    </div>
  )

  const activitiesSelect = () => {
    const stringItems = _.uniq(
      _.reject(activities, (activity) => _.isEmpty(activity)),
    )

    return (
      <div>
        <Select
          name="activities"
          isMulti
          defaultValue={filters.activities}
          onChange={(option) => handleChange("activities", option)}
          placeholder="Activities…"
          options={stringItems.map((item) => ({ label: item, value: item }))}
        />
      </div>
    )
  }

  const standardsSelect = () => (
    <div>
      <Select
        name="standards"
        isMulti
        defaultValue={filters.standards}
        onChange={(option) => handleChange("standards", option)}
        placeholder="Indicators…"
        options={indicators}
      />
    </div>
  )

  const dessaCompetenciesSelect = () => {
    if (isDessaReport === false) {
      return null
    }

    const selectedLoadIndicators = filters.load_indicators || []
    return (
      <div>
        <Select
          name="dessa_competencies"
          isMulti
          defaultValue={selectedLoadIndicators}
          onChange={(option) => handleChange("dessa_competencies", option)}
          placeholder="DESSA Competencies…"
          options={convertValuesIntoFilters(
            window.gon.DESS_SEL_STANDARDS,
            selectedLoadIndicators,
          )}
        />
      </div>
    )
  }
  const isSELScan = () => _.isObject(filters.activities)

  return (
    <div className="px-2 py-4 overall-body">
      <div className="row">
        <div className={isDessaReport === false ? "col-4" : "col-3"}>
          {categories()}
        </div>
        <div className={isDessaReport === false ? "col-4" : "col-3"}>
          {tiersOfSupport()}
        </div>
        {isSELScan() && (
          <div className={isDessaReport === false ? "col-4" : "col-3"}>
            {activitiesSelect()}
          </div>
        )}
        {!isSELScan() && (
          <div className={isDessaReport === true ? "col-3" : "col-3"}>
            {standardsSelect()}
          </div>
        )}
        <div className={isDessaReport === true ? "col-3" : "col-3"}>
          {dessaCompetenciesSelect()}
        </div>
      </div>
    </div>
  )
}
export default ProgramMatrixFilters
